<template>
  <b-popover
    container="body"
    title=""
    placement="bottomleft"
    :show.sync="show"
    :target="target"
    :triggers="triggers"
    custom-class="overlay-tooltip"
    :boundary-padding="0"
    @show="onShow"
    @hide="onHide"
    :offset="offset"
    boundary="viewport"
    :ref="`${target}_popover`"
  >
    <div class="c--shared_overlay-tooltip d-flex flex-column justify-content-start align-items-start">
      <div v-if="isCloneTarget" class="target-icon" v-html="targetClone" @click.prevent="closeTooltip"></div>
      <p class="overlay-tooltip-text">
        {{ text }}
      </p>
      <b-button data-testid="ok-button" variant="overlay-tooltip-cta" block @click.prevent="closeTooltip">
        {{ buttonText }}
      </b-button>
    </div>
  </b-popover>
</template>

<script>
  export default {
    props: {
      target: {
        type: String,
        required: true,
      },
      triggers: {
        type: String,
        required: false,
        default: 'click',
      },
      showOverlay: {
        type: Boolean,
        required: false,
        default: true,
      },
      text: {
        type: String,
        required: true,
      },
      buttonText: {
        type: String,
        required: true,
      },
      offset: {
        type: Number,
        required: false,
        default: 0,
      },
      isCloneTarget: {
        type: Boolean,
        required: false,
        default: false,
      },
      hidingType: {
        // circle, square
        type: String,
        required: false,
        default: 'circle',
      },
    },

    data() {
      return {
        show: false,
        overlayClass: `overlay-tooltip-backdrop`,
        targetClone: null,
        targetEl: null,
      }
    },

    methods: {
      closeTooltip() {
        this.show = false
      },
      onShow(e) {
        this.targetEl = e.target
      },
      onHide() {
        this.$emit('onHideOverlayTooltip')
      },
      outerHTML(node) {
        return node.outerHTML || new XMLSerializer().serializeToString(node)
      },
    },
  }
</script>
