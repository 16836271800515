var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-popover', {
    ref: `${_vm.target}_popover`,
    attrs: {
      "container": "body",
      "title": "",
      "placement": "bottomleft",
      "show": _vm.show,
      "target": _vm.target,
      "triggers": _vm.triggers,
      "custom-class": "overlay-tooltip",
      "boundary-padding": 0,
      "offset": _vm.offset,
      "boundary": "viewport"
    },
    on: {
      "update:show": function ($event) {
        _vm.show = $event;
      },
      "show": _vm.onShow,
      "hide": _vm.onHide
    }
  }, [_c('div', {
    staticClass: "c--shared_overlay-tooltip d-flex flex-column justify-content-start align-items-start"
  }, [_vm.isCloneTarget ? _c('div', {
    staticClass: "target-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.targetClone)
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.closeTooltip.apply(null, arguments);
      }
    }
  }) : _vm._e(), _vm._v(" "), _c('p', {
    staticClass: "overlay-tooltip-text"
  }, [_vm._v("\n      " + _vm._s(_vm.text) + "\n    ")]), _vm._v(" "), _c('b-button', {
    attrs: {
      "data-testid": "ok-button",
      "variant": "overlay-tooltip-cta",
      "block": ""
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.closeTooltip.apply(null, arguments);
      }
    }
  }, [_vm._v("\n      " + _vm._s(_vm.buttonText) + "\n    ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }